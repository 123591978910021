import React from 'react'
import logo from '../assets/images/logo.png'

const Banner = (props) => (
    <section id="banner" className="major">
        <div className="inner">
            <header className="logoimg">
                 <span className="image logo"><img src={logo} alt="" className='invert' /></span>

            </header>
            <div className="content">
                <p>Arte y Juego. Herramientas para incentivar y fortalecer nuestros procesos de creación y nuestra creatividad</p>
                <ul className="actions">
                    <li><a href="/revelarte" className="button next scrolly">VER MÁS.</a></li>
                </ul>

            </div>

             <ul className="icons">
                 <li><a href="https://www.facebook.com/RevelArte-Herramientas-para-la-creatividad-104232234762224" target='_blank' className="icon alt fa-facebook"><span className="label">Facebook</span></a></li>
                <li><a href="https://www.instagram.com/proyectorevelarteok" target='_blank' className="icon alt fa-instagram"><span className="label">Instagram</span></a></li>
              </ul>
        </div>
     </section>
)

export default Banner
