import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import pic01 from '../assets/images/p11.jpg'
import pic02 from '../assets/images/p17fix.jpg'
import pic03 from '../assets/images/p6.jpg'
import pic04 from '../assets/images/p4.jpg'
import pic05 from '../assets/images/p16fix.jpg'
import pic06 from '../assets/images/p20.jpg'
import Feed from "react-instagram-authless-feed"

class HomeIndex extends React.Component {
    render() {

        return (
            <Layout>
                <Helmet
                    title="RevelARTE - Arte y Juego"
                    meta={[
                        { name: 'description', content: 'RevelARTE - Arte y Juego' },
                        { name: 'keywords', content: 'RevelARTE,Arte,Juego,terapia,acompanamiento' },
                    ]}
                >
                </Helmet>

                <Banner />
<div id="quotesection">                    <section id="quote" >
    
</section>
</div>
                <div id="main">
                    <section id="one" className="tiles">
                        <article className='home' style={{backgroundImage: `url(${pic02})`}}>
                        
                        <header className="major">
                        <h3>Talleres</h3>
                                <p>Despierta tu cretividad.</p>
                            </header>
                            <Link to="/talleres" className="link primary"></Link>

                        </article>
                        <article className='home instabox' style={{backgroundImage: `url(${pic01})`}}>
                        <header className="major">
                        <h3>Galeria</h3>
                        </header>
                            <Feed userName="proyectorevelarteok" className="Feed" classNameLoading="Loading"/>
                            <Link to="/galeria" className="link primary"></Link>

                         </article>
                        <article className='home' style={{backgroundImage: `url(${pic05})`}}>
                        <header className="major">
                        <h3>Biblioteca</h3>
                                <p>Contenidos para profundizar.</p>
                            </header>
                            <Link to="/proximamente" className="link primary"></Link>
                            
                        </article>
                        <article className='home' style={{backgroundImage: `url(${pic06})`}}>
                        <header className="major">
                            <h3>Entrevistas</h3>
                                <p>Conoce otras experiencias.</p>
                            </header>
                            <Link to="/proximamente" className="link primary"></Link>
                        </article>
                     
                        <article className='home' style={{backgroundImage: `url(${pic03})`}}>
                        <header className="major">
                                <h3>Tienda</h3>
                                <p>Potencia tu aprendizaje.</p>
                            </header>
                            <Link to="/proximamente" className="link primary"></Link>
                        </article>
                        <article className='home' style={{backgroundColor: 'rgb(72, 72, 72)'}}>
                                 
                                <blockquote>En el estado creativo ... volvemos al punto de partida de una sensibilidad abierta a las sensaciones que nos conectan con lo inmediato de adentro de nosostros mismos. <br /> (F. Moccio)</blockquote>
                         </article>
                    
  
                    </section>
                     
                </div>

            </Layout>
        )
    }
}

export default HomeIndex